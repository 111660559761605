import { useState } from "react";
import "./App.css";

import "preline/preline";
import { IStaticMethods } from "preline/preline";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const css = `
@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #141414;
  overflow: hidden;
}

.background span {
  width: 43vmin;
  height: 43vmin;
  border-radius: 43vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 15;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #5f3bf0;
  top: 21%;
  left: 40%;
  animation-duration: 341s;
  animation-delay: -183s;
  transform-origin: 18vw -7vh;
  box-shadow: 86vmin 0 11.682617954026696vmin currentColor;
}
.background span:nth-child(1) {
  color: #a262ff;
  top: 1%;
  left: 88%;
  animation-duration: 70s;
  animation-delay: -164s;
  transform-origin: 18vw 3vh;
  box-shadow: -86vmin 0 11.382297276349112vmin currentColor;
}
.background span:nth-child(2) {
  color: #a262ff;
  top: 76%;
  left: 40%;
  animation-duration: 157s;
  animation-delay: -301s;
  transform-origin: 1vw -11vh;
  box-shadow: 86vmin 0 10.99023771048347vmin currentColor;
}
.background span:nth-child(3) {
  color: #10c49c;
  top: 3%;
  left: 67%;
  animation-duration: 216s;
  animation-delay: -142s;
  transform-origin: 13vw -8vh;
  box-shadow: 86vmin 0 10.991170129335774vmin currentColor;
}
.background span:nth-child(4) {
  color: #a262ff;
  top: 87%;
  left: 52%;
  animation-duration: 228s;
  animation-delay: -56s;
  transform-origin: -13vw 4vh;
  box-shadow: 86vmin 0 11.19599662481546vmin currentColor;
}
.background span:nth-child(5) {
  color: #10c49c;
  top: 77%;
  left: 42%;
  animation-duration: 314s;
  animation-delay: -332s;
  transform-origin: 25vw -18vh;
  box-shadow: 86vmin 0 11.245237425400445vmin currentColor;
}
.background span:nth-child(6) {
  color: #10c49c;
  top: 62%;
  left: 67%;
  animation-duration: 57s;
  animation-delay: -63s;
  transform-origin: 5vw 3vh;
  box-shadow: -86vmin 0 10.805079471777264vmin currentColor;
}
.background span:nth-child(7) {
  color: #a262ff;
  top: 3%;
  left: 45%;
  animation-duration: 156s;
  animation-delay: -270s;
  transform-origin: 13vw -20vh;
  box-shadow: -86vmin 0 11.127693030107979vmin currentColor;
}
.background span:nth-child(8) {
  color: #5f3bf0;
  top: 18%;
  left: 23%;
  animation-duration: 211s;
  animation-delay: -66s;
  transform-origin: 14vw 1vh;
  box-shadow: 86vmin 0 10.929394773043136vmin currentColor;
}
.background span:nth-child(9) {
  color: #a262ff;
  top: 26%;
  left: 7%;
  animation-duration: 265s;
  animation-delay: -154s;
  transform-origin: 23vw -14vh;
  box-shadow: -86vmin 0 11.587500105695383vmin currentColor;
}
.background span:nth-child(10) {
  color: #a262ff;
  top: 49%;
  left: 80%;
  animation-duration: 124s;
  animation-delay: -134s;
  transform-origin: -1vw -1vh;
  box-shadow: -86vmin 0 11.580041507578908vmin currentColor;
}
.background span:nth-child(11) {
  color: #5f3bf0;
  top: 72%;
  left: 35%;
  animation-duration: 329s;
  animation-delay: -258s;
  transform-origin: 7vw 25vh;
  box-shadow: -86vmin 0 11.355039807251819vmin currentColor;
}
.background span:nth-child(12) {
  color: #5f3bf0;
  top: 4%;
  left: 62%;
  animation-duration: 78s;
  animation-delay: -85s;
  transform-origin: 22vw -13vh;
  box-shadow: 86vmin 0 11.454948948613996vmin currentColor;
}
`;

const FORM_EMAIL_ID = "email";
const FORM_ACTION_URL = "https://mailinglist.healthii.de";

function App() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [showImprint, setShowImprint] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append(FORM_EMAIL_ID, email);

    fetch(FORM_ACTION_URL, {
      body: formData,
      method: "post",
      mode: "no-cors",
    })
      .then(() => {
        setEmail("");
        setSuccess(true);
      })
      .catch(() => {});
  };

  return (
    <div className="App light bg-black h-full flex">
      <div className="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <style>{css}</style>
      <main
        id="content"
        role="main"
        className="relative max-w-3xl px-4 sm:px-6 lg:px-8 flex flex-col justify-center sm:items-center mx-auto size-full before:absolute before:top-0 before:start-1/2 before:bg-no-repeat before:bg-top before:size-full before:-z-[1] before:transform before:-translate-x-1/2"
      >
        <div className="text-center bg-black/60 rounded-2xl py-8 px-4 sm:px-6 lg:px-8">
          <svg
            width="150px"
            height="50px"
            fill="none"
            viewBox="100 -20 500 255"
            className="w-28 sm:w-36 h-auto mx-auto mt-2 mb-2 sm:mb-4"
          >
            <g>
              <path
                fill="#ffffff"
                d="M102.29,71.7c9.01,9.01,13.51,21.96,13.51,38.87v72.29h-11.85v-71.34c0-13.9-3.52-24.49-10.55-31.76
		c-7.03-7.27-16.87-10.9-29.51-10.9c-14.54,0-25.99,4.35-34.36,13.04c-8.38,8.69-12.56,20.38-12.56,35.08v65.89H5.12V7h11.85v77.97
		c4.27-8.53,10.58-15.13,18.96-19.79c8.37-4.66,18.25-6.99,29.62-6.99C81.04,58.19,93.29,62.69,102.29,71.7z"
              />
              <path
                fill="#ffffff"
                d="M247.81,123.84H141.4c0.31,9.64,2.76,18.21,7.35,25.71c4.58,7.51,10.78,13.31,18.6,17.42c7.82,4.11,16.63,6.16,26.43,6.16
		c8.06,0,15.52-1.46,22.4-4.38c6.87-2.92,12.6-7.23,17.18-12.92l6.87,7.82c-5.37,6.48-12.13,11.46-20.26,14.93
		c-8.14,3.48-16.95,5.21-26.43,5.21c-12.32,0-23.31-2.69-32.94-8.06c-9.64-5.37-17.18-12.8-22.63-22.28s-8.18-20.3-8.18-32.47
		c0-12.01,2.57-22.79,7.7-32.35c5.13-9.56,12.21-17.02,21.21-22.4c9.01-5.37,19.12-8.06,30.34-8.06c11.22,0,21.29,2.65,30.22,7.94
		c8.92,5.3,15.96,12.68,21.09,22.16c5.13,9.48,7.7,20.23,7.7,32.23L247.81,123.84z M165.57,74.42c-6.95,3.87-12.52,9.32-16.71,16.35
		c-4.19,7.03-6.6,14.97-7.23,23.82h94.8c-0.47-8.85-2.84-16.75-7.11-23.7c-4.27-6.95-9.88-12.4-16.83-16.35
		c-6.95-3.95-14.78-5.92-23.46-5.92C180.34,68.62,172.52,70.55,165.57,74.42z"
              />
              <path
                fill="#ffffff"
                d="M347.59,69.92c8.21,7.82,12.32,19.32,12.32,34.48v78.45h-11.38v-22.04c-3.95,7.27-9.72,12.92-17.3,16.95
		c-7.58,4.03-16.67,6.04-27.25,6.04c-13.75,0-24.61-3.24-32.59-9.72c-7.98-6.48-11.97-15.01-11.97-25.6c0-10.27,3.67-18.6,11.02-25
		c7.35-6.4,19.08-9.6,35.19-9.6h42.42v-9.95c0-11.53-3.12-20.3-9.36-26.31c-6.24-6-15.37-9.01-27.37-9.01
		c-8.22,0-16.08,1.42-23.58,4.27c-7.51,2.84-13.86,6.64-19.08,11.38l-5.93-8.53c6.16-5.53,13.59-9.84,22.28-12.92
		c8.69-3.08,17.77-4.62,27.25-4.62C327.6,58.19,339.37,62.1,347.59,69.92z M331.83,166.97c7.19-4.74,12.6-11.61,16.23-20.62v-23.23
		h-42.19c-12.17,0-20.97,2.21-26.43,6.64c-5.45,4.43-8.18,10.51-8.18,18.25c0,8.06,3,14.42,9.01,19.08c6,4.66,14.46,6.99,25.36,6.99
		C315.91,174.08,324.63,171.71,331.83,166.97z"
              />
              <path
                fill="#ffffff"
                d="M395.7,174.91c-5.53-5.92-8.29-14.1-8.29-24.53V7h11.85v141.96c0,8.06,1.81,14.18,5.45,18.37
		c3.63,4.19,9.08,6.28,16.35,6.28c4.11,0,8.06-0.79,11.85-2.37l0.95,9.95c-4.43,1.74-9.17,2.61-14.22,2.61
		C409.21,183.8,401.23,180.84,395.7,174.91z"
              />
              <path
                fill="#ffffff"
                d="M507.56,175.03c-3,2.84-6.75,5.02-11.26,6.52c-4.5,1.5-9.21,2.25-14.1,2.25c-10.75,0-19.04-2.96-24.88-8.89
		c-5.85-5.92-8.77-14.18-8.77-24.77V69.33H425.8V59.14h22.75V32.12h11.85v27.02h39.34v10.19H460.4v79.63
		c0,8.06,1.93,14.18,5.81,18.37c3.87,4.19,9.6,6.28,17.18,6.28c7.74,0,14.14-2.29,19.2-6.87L507.56,175.03z"
              />
              <path
                fill="#ffffff"
                d="M621.08,71.7c9.01,9.01,13.51,21.96,13.51,38.87v72.29h-11.85v-71.34c0-13.9-3.52-24.49-10.55-31.76
		c-7.03-7.27-16.87-10.9-29.51-10.9c-14.54,0-25.99,4.35-34.36,13.04c-8.38,8.69-12.56,20.38-12.56,35.08v65.89h-11.85V7h11.85
		v77.97c4.27-8.53,10.58-15.13,18.96-19.79c8.37-4.66,18.25-6.99,29.62-6.99C599.83,58.19,612.08,62.69,621.08,71.7z"
              />
            </g>
            <g>
              <path
                fill="#12c49c"
                d="M658.23,27.38c-2.37-2.37-3.56-5.21-3.56-8.53c0-3.16,1.19-5.93,3.56-8.29C660.6,8.18,663.52,7,667,7
		c3.47,0,6.4,1.15,8.77,3.44c2.37,2.29,3.55,5.02,3.55,8.18c0,3.48-1.19,6.4-3.55,8.77c-2.37,2.37-5.3,3.56-8.77,3.56
		C663.52,30.93,660.6,29.75,658.23,27.38z M658.46,58.19h16.83v124.66h-16.83V58.19z"
              />
              <path
                fill="#12c49c"
                d="M688.8,27.38c-2.37-2.37-3.56-5.21-3.56-8.53c0-3.16,1.19-5.93,3.56-8.29c2.37-2.37,5.29-3.56,8.77-3.56
		c3.47,0,6.4,1.15,8.77,3.44c2.37,2.29,3.55,5.02,3.55,8.18c0,3.48-1.19,6.4-3.55,8.77c-2.37,2.37-5.3,3.56-8.77,3.56
		C694.09,30.93,691.17,29.75,688.8,27.38z M689.04,58.19h16.83v124.66h-16.83V58.19z"
              />
            </g>
          </svg>
          <p className="text-sm text-gray-200">
            Der Gesundheitsmarkt erlebt derzeit endlich die digitale
            Transformation. Wir wollen aus den neu entstehenden Möglichkeiten
            innovative Produkte schaffen, die es Menschen ermöglichen ihre
            Gesundheit besser zu managen.
            <br />
            <br />
            In einem ersten Schritt werden wir eine kundenzentrierte, moderne{" "}
            <span className="bg-clip-text bg-gradient-to-tr from-green-300 to-purple-400 text-transparent font-semibold">
              Online-Apotheke
            </span>{" "}
            aufbauen, die durch Qualität und innovative Health-Services ein
            Wow-Erlebnis bei unseren Kunden auslösen soll.
          </p>
          <br />
          <p className="text-md text-white font-semibold">
            Jetzt in die Warteliste eintragen und als einer der Ersten Zugang zu
            unseren Services erhalten.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mt-8 space-y-4">
              <div>
                <label
                  htmlFor="hs-cover-with-gradient-form-email-1"
                  className="sr-only"
                >
                  E-Mail-Adresse
                </label>
                {!success && (
                  <div className="relative">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="hs-cover-with-gradient-form-email-1"
                      className="py-3 ps-11 pe-4 block w-full bg-white/[.03] border-2 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11"
                      placeholder="E-Mail-Adresse"
                      autoComplete="email"
                    />
                    <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                      <svg
                        className="flex-shrink-0 size-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <rect width="20" height="16" x="2" y="4" rx="2" />
                        <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                      </svg>
                    </div>
                  </div>
                )}
              </div>

              <div className="grid">
                <button
                  disabled={success}
                  type="submit"
                  className="sm:p-4 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-white/10 text-white hover:bg-white/20 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  {success
                    ? "Vielen Dank! Wir melden uns."
                    : "Der Warteliste beitreten"}
                  {!success && (
                    <svg
                      className="flex-shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m9 18 6-6-6-6" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
      <footer className="absolute bottom-0 inset-x-0 text-center py-5">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
          {!showImprint && (
            <p className="text-sm text-white/50">
              © 2024 Healthii GmbH{" | "}
              <button onClick={() => setShowImprint(true)}>Impressum</button>.
            </p>
          )}
          {showImprint && (
            <>
              <br />
              <p className="text-sm text-white/50">Healthii GmbH</p>
              <p className="text-sm text-white/50">Julius-Hatry-Straße 1</p>
              <p className="text-sm text-white/50">68163 Mannheim</p>
              <p className="text-sm text-white/50">
                Handelsregister HRB 749314 Mannheim
              </p>
              <p className="text-sm text-white/50">
                Vertreten durch: Frank Müller, Tobias Kindlieb
              </p>
              <p className="text-sm text-white/50">
                E-Mail: jobs [at] healthii [punkt] de
              </p>
            </>
          )}
        </div>
      </footer>
    </div>
  );
}

export default App;
